import React, {useRef} from "react";
import {Button, Icon, PhotoBrowser} from "framework7-react";

import {useAuth} from "contexts/auth";

import styles from "./profile.module.css";

const ToolbarHeader = () => {
  const popupQR = useRef(null);
  const { session, setSession } = useAuth();

  const qrcodeLink = () => {
    if (!session.user.profile) return;
    let membership = session.user.profile.membership_id;
    let link = "https://quickchart.io/qr?text=";
    link += encodeURI("GPYI ID: " + membership) + "%0A";
    link += encodeURI("NAME: " + session.user.profile.full_name);
    link += "&chs=480x480&choe=UTF-8&chld=L|2";
    return link;
  }

  return (
    <div className={styles.toolbar}>
      <Button onClick={() => popupQR.current.open()} fill>
        <Icon f7="barcode_viewfinder"/>
      </Button>
      <Button href="/profile/edit" fill>
        <Icon f7="pencil"/>
      </Button>
      <PhotoBrowser toolbar={false} photos={[qrcodeLink()]} ref={popupQR} />
    </div>
  );
}

export default ToolbarHeader;
