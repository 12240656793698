import React, {useRef} from "react";
import {CardHeader, CardContent, Card, Row, Col, PhotoBrowser, Icon} from "framework7-react";

import styles from "./home.module.css";

const QrisDonation = () => {
  const photoBrowser = useRef(null);
  const qris = "https://media.storage.gpyindonesia.org/qris-gpfi.png";

  return (
    <Card>
      <CardHeader><b>Give Your Support <Icon f7="heart"/></b></CardHeader>
      <CardContent>
        <Row>
          Support our program by giving your donation, please scan the QR below.
          <Col className={styles.qris}>
            <img src={qris} alt="QRIS Donation" onClick={() => photoBrowser.current.open()} />
            <PhotoBrowser toolbar={false} photos={[qris]} theme="dark" ref={photoBrowser} />
          </Col>
        </Row>
      </CardContent>
    </Card>
  )
}

export default QrisDonation;
